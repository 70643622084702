<template>
	<div class="imglist">
		<div class="item" :style="{width:width+'px',height:width+'px'}" v-for="(item,index) in datas" :key="item">
			<img :src="item">
			<div class="delbtn" @click="del(index,item)"><i class="el-icon-close"></i></div>
		</div>
	</div>
</template>

<script>
	export default {
		name:'imglist',
		data() {
			return {
				
			};
		},
		props:{
			width:{
				type:Number,
				default:148
			},
			datas:{
				type:Array
			}
		},
		watch:{
			// datas(){
			// 	this.$emit('change',this.datas);
			// }
		},
		methods: {
			del(index,item){
				this.datas.splice(index,1);
				//this.$request.get('/api/custauth/deleteUploadFile',{Location:item});
				
			}
		}
	};
</script>

<style lang="less" scoped >
	.imglist{display: inline-flex;flex-wrap: wrap;
		.item{width: 148px;height: 148px;position: relative;border: 1px solid #c0ccda;border-radius: 6px;margin:0 8px 8px 0;box-sizing: border-box;
			img{width: 100%;height: 100%;border-radius: 6px;object-fit: cover;}
			.delbtn{position: absolute;right: -1px;top: -1px;width:20px;height: 20px;background-color:#f00;border-radius: 0 6px 0 6px;font-size: 12px;color: #fff;display: flex;align-items: center;justify-content: center;line-height: 1.2;cursor: pointer;visibility:hidden;}
			&:hover{
				.delbtn{visibility: visible;}
			}
		}
	}
</style>